import { Row } from "antd";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import ArrowR from "../../assets/contenido/FerreNoticias/arrowR.png";
import recoverCollection from "../../data/getData";
import Title from "../Title";

const ShowNews = ({ id, history }) => {
  let [news, setNews] = useState([]);
  const [currentPage, setCurrentPage] = useState();
  const [perPage, setPerPage] = useState();

  useEffect(() => {
    recoverCollection("noticias").then((response) => {
      const data = response.filter((n) => {
        return n.type.toUpperCase() === "generales".toUpperCase();
      });
      data.sort((a, b) => b.date.localeCompare(a.date));
      setNews(data);
      setPerPage(8);
      if (id) {
        setCurrentPage(id - 1);
      } else {
        setCurrentPage(0);
      }
    });
  }, [id]);

  const PER_PAGE = perPage;
  const offset = currentPage * PER_PAGE;
  const pageCount = Math.ceil(news.length / PER_PAGE);
  const sliceNews = news.slice(offset, offset + PER_PAGE);

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
    history.push(`/noticias-chilemat/${selectedPage + 1}`);
  };
  return (
    <section className="shownews-container">
      <Title className="title-section-container" value="NOTICIAS" />
      <Row className="container-news">
        {sliceNews.map((val, index) => {
          const date = new Date(val.date);
          const year = date.getFullYear();
          const monthNames = [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
          ];
          let month = monthNames[date.getMonth()];
          let dt = date.getDate();
          if (dt < 10) {
            dt = "0" + dt;
          }
          if (month < 10) {
            month = "0" + month;
          }
          const newDate = `${dt} ${month} ${year}`;
          return (
            <div key={val.id} className="card-news-container">
              <div
                onClick={() => {
                  history.push(`/Noticia/${val.url}`);
                }}
                className="card-news-info"
              >
                <div
                  className="img-news-container"
                  style={{ backgroundImage: `url(${val.cardImg})` }}
                ></div>
                <div className="text-news-container">
                  <p className="news-date">{newDate}</p>
                  <h3 className="news-title">{val.title}</h3>
                  <p className="news-paragraph">{val.resume}</p>
                  <div className="read-more-button">
                    <img alt="Flecha dirección Derecha" src={ArrowR} />
                    VER MÁS
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Row>
      <ReactPaginate
        previousLabel={"P"}
        nextLabel={"N"}
        initialPage={currentPage}
        forcePage={currentPage}
        pageCount={pageCount}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        previousLinkClassName={"pagination__link"}
        nextLinkClassName={"pagination__link"}
        disabledClassName={"pagination__link--disabled"}
        activeClassName={"pagination__link--active"}
      />
    </section>
  );
};
export default ShowNews;
