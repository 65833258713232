import React, {useEffect, useState} from 'react';
import { Row } from 'antd';
import ironmonger from '../../assets/contenido/Beneficios de Pertenecer/como ser parte/ironmonger.png'
import financial from '../../assets/contenido/Beneficios de Pertenecer/como ser parte/financial.png'
import experience from '../../assets/contenido/Beneficios de Pertenecer/como ser parte/experience.png'
import { useHistory } from "react-router-dom";
import UseWindowSize from '../HookSize';
import { Swiper, SwiperSlide } from "swiper/react";
import fondoDesk from "../../assets/contenido/Beneficios de Pertenecer/como ser parte/how-to-be-part.png"
import fondoMob from "../../assets/contenido/Beneficios de Pertenecer/como ser parte/fondo-mob.png"
// import Swiper core and required modules
import SwiperCore, {
  Navigation, Pagination
} from 'swiper/core';
SwiperCore.use([Navigation, Pagination]);

const HowToBePart=(props)=>{
  let history = useHistory();
  const size = UseWindowSize();
  const [Banners, setBanners] = useState([]);

  const dataHowToBePart = [
    {
      titleCard: "EXPERIENCIA",
      imgCard: experience,
      textCard: "Tener a lo menos un año de funcionamiento como empresario ferretero"
    },
    {
      titleCard: "FINANCIERO",
      imgCard: financial,
      textCard: "Tener un buen comportamiento financiero."
    },
    {
      titleCard: "FERRETERO",
      imgCard: ironmonger,
      textCard: "Tener corazón y alma de ferretero."
    }
  ]
    useEffect(() => {
      if(size.width>=992){
        setBanners(fondoDesk)
      }if(size.width<=991){
        setBanners(fondoMob)
      }
    }, [size.width]);
  return(  
    <section>
      <Row id={props.id} className="howToBePart-section-container" style={{ backgroundImage: `url(${Banners})` }}>
        <h1>¿Quieres ser parte de Chilemat?</h1>
        {
          size.width>=768
          ?
          <div className="howToBePart-cards-container">
            {
              dataHowToBePart.map((val, index)=>{
                return (
                  <div key={index} className="howToBePart-cards-fixed">
                    <h3>{val.titleCard}</h3>
                    <img className="howToBePart-img-card" src={val.imgCard} alt="icon como ser parte"/>
                    <p>{val.textCard}</p>
                  </div>
                )
              })
            }
          </div>
          :
          <Swiper
          className="howToBePart-cards-container"
          navigation={Navigation}
          freeMode={true}
          slidesPerView={1}>
            {
              dataHowToBePart.map((val, index)=>{
                return <SwiperSlide  key={index}>
                    <div className="howToBePart-cards-info">
                      <h3>{val.titleCard}</h3>
                      <img className="howToBePart-img-card" src={val.imgCard} alt="icon como ser parte"/>
                      <p>{val.textCard}</p>
                    </div>
                  </SwiperSlide>
              })
            }
          </Swiper>
        }
        <h2>Si quieres ser parte</h2>
        <button onClick={()=>{history.push("/unete-como-ferretero")}}
        type="button" className='howToBePart-button'>
          pincha aquí
        </button>
      </Row>
    </section>
  )

}
export default HowToBePart;