import { db } from "../../data/firebaseInit";
import React, { useContext, useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import MyContext from '../../states/MyContext';
import { regiones } from '../map/autocomplete';
import UseWindowSize from '../HookSize';
import fondoDesk from "../../assets/contenido/Formulario Contacto/fondo2.png";
import fondoMob from "../../assets/contenido/Formulario Contacto/fondo3.png";

const AssociatedForm = (props) => {
    const { state, dispatch } = useContext(MyContext);
    const { nameAssociatedForm, lastnameAssociatedForm, emailAssociatedForm, phoneAssociatedForm, directionAssociatedForm, comunaAssociatedForm, regionAssociatedForm, ferreAssociatedForm } = state;
    const size = UseWindowSize();
    const [Banners, setBanners] = useState(fondoDesk);

    useEffect(() => {
        if (size.width >= 992) {
            setBanners(fondoDesk);
        } else {
            setBanners(fondoMob);
        }
    }, [size.width]);

    const onChange = (e) => {
        dispatch({
            type: "saveUserInformation",
            field: e.target.name,
            value: e.target.value
        });
    };

    const submitMail = (e) => {
        e.preventDefault();
        if (nameAssociatedForm === '' || emailAssociatedForm === '' || phoneAssociatedForm === '' || comunaAssociatedForm === '' || ferreAssociatedForm === '') {
            document.getElementById("validation").style.border = 'solid red';
            document.getElementById("message-validation").innerHTML = "Completa los campos solicitados";
        } else {
            document.getElementById("validation").style.border = 'transparent';
            document.getElementById("message-validation").innerHTML = "";

            db.collection("mail").add({
                to: "chilemat.contacto@chilemat.cl",
                message: {
                    subject: `¡Queremos formar parte! ${ferreAssociatedForm}`,
                    html: `Nombre: ${nameAssociatedForm} ${lastnameAssociatedForm} <br />E-mail: ${emailAssociatedForm} <br />Telefono: ${phoneAssociatedForm} <br />Dirección ${directionAssociatedForm}<br />Comuna: ${comunaAssociatedForm}<br />Región: ${regionAssociatedForm}<br />Nombre de la ferretería:${ferreAssociatedForm}`,
                },
            });

            dispatch({ type: 'reset' });
            props.openModal();
        }
    };

    return (
        <section className="AssociatedForm-section" style={{ backgroundImage: `url(${Banners})` }}>
            <div className="AssociatedForm-content">
                <div className="form-container AssociatedForm-Info">
                    <h1>
                        <span className="text-marked">GRACIAS POR QUERER SER PARTE DE LA GRAN FAMILIA CHILEMAT</span>
                    </h1>
                    <p className="text-marked">Déjanos tus datos para poder contactarte</p>
                    <form method="POST" action="/contact" encType="multipart/form-data">
                        <div id='validation'>
                            <div id='message-validation'></div>
                            <label>NOMBRE</label>
                            <input
                                type="text"
                                name="nameAssociatedForm"
                                id="nameAssociatedForm"
                                onChange={onChange}
                                value={nameAssociatedForm}
                            />
                            <label>APELLIDO</label>
                            <input
                                type="text"
                                name="lastnameAssociatedForm"
                                id="lastnameAssociatedForm"
                                onChange={onChange}
                                value={lastnameAssociatedForm}
                            />
                            <Row className="form-container-row" gutter={[8, 8]}>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <label>CORREO</label>
                                    <input
                                        type="email"
                                        name="emailAssociatedForm"
                                        id="emailAssociatedForm"
                                        onChange={onChange}
                                        value={emailAssociatedForm}
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <label>TELÉFONO</label>
                                    <input
                                        type="tel"
                                        name="phoneAssociatedForm"
                                        id="phoneAssociatedForm"
                                        maxLength="9"
                                        onChange={onChange}
                                        value={phoneAssociatedForm}
                                    />
                                </Col>
                            </Row>
                            <label>DIRECCIÓN</label>
                            <input
                                type="text"
                                name="directionAssociatedForm"
                                id="directionAssociatedForm"
                                onChange={onChange}
                                value={directionAssociatedForm}
                            />
                            <Row className="form-container-row" gutter={[8, 8]}>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <label>COMUNA</label>
                                    <input
                                        type="text"
                                        name="comunaAssociatedForm"
                                        id="comunaAssociatedForm"
                                        className="input-comuna"
                                        onChange={onChange}
                                        value={comunaAssociatedForm}
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <label>REGIÓN</label>
                                    <select
                                        name="regionAssociatedForm"
                                        id="regionAssociatedForm"
                                        onChange={onChange}
                                        className="input-region"
                                        value={regionAssociatedForm}
                                    >
                                        <option value="region"></option>
                                        {regiones.map((item, i) => (
                                            <option key={i} value={item.id}>{item.name}</option>
                                        ))}
                                    </select>
                                </Col>
                            </Row>
                            <label>NOMBRE DE TU FERRETERÍA</label>
                            <input
                                type="text"
                                name="ferreAssociatedForm"
                                id="ferreAssociatedForm"
                                onChange={onChange}
                                value={ferreAssociatedForm}
                            />
                        </div>
                        <div className="container-button">
                            <button type="button" onClick={submitMail} className="form-item form-button">
                                ENVIAR
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
}

export default AssociatedForm;
