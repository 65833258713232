import React from "react";
import NewStoresSlider from "../components/noticias/NewStoresSlider";
import ShowNews from "../components/noticias/NewsShow";

const BlogNews = ({ match, history }) => {
  const {
    params: { id },
  } = match;

  return (
    <>
      <ShowNews id={id} history={history} />
      <NewStoresSlider history={history} />
    </>
  );
};
export default BlogNews;
