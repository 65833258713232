import React, {useState} from "react";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { MenuOutlined, CloseOutlined} from '@ant-design/icons';
const MenuHeader = (props) =>{
    const [click, setClick] = useState(false)
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const toggle = () =>{
        setClick(!click)
    }
    const scrollEnd = (el) =>{
        el.scrollIntoView({ behavior: 'auto', block: 'end' })
    }
    const scrollCenter = (el) =>{
        el.scrollIntoView({ behavior: 'auto', block: 'center' })
    }
    return(
        <div className="navBar-menu">
            <div className="menu-icon" onClick={toggle}>
                {click ? <CloseOutlined/> : <MenuOutlined/>}
            </div>
            <div className={click ? "nav-menu active" : "nav-menu"}>
                <div className={splitLocation[1] === "conocenos" ? "activeLink" : ""} onClick={toggle}>
                    <Link className="titleLink" to="/conocenos">nuestra empresa</Link>
                    <ul className='submenuDesk'>
                        <li><HashLink smooth to="/conocenos#">historia de chilemat</HashLink></li>
                        <li><HashLink smooth to="/conocenos#mision-vision-y-valores" scroll={scrollCenter}>Misión, Visión y Valores</HashLink></li>
                        <li><HashLink smooth to="/conocenos#Directorio">directorio</HashLink></li>
                        <li><HashLink smooth to="/conocenos#nuestro-equipo" scroll={scrollCenter}>nuestro equipo</HashLink></li>
                    </ul>
                </div>
                <div className={splitLocation[1] === "encuentra-tu-ferreteria" ? "activeLink" : ""} onClick={toggle}>
                    <Link className="titleLink" to="/encuentra-tu-ferreteria">ENCUENTRA TU FERRETERÍA</Link>
                </div>
                <div className={splitLocation[1] === "noticias-chilemat" ? "activeLink" : ""} onClick={toggle}>
                    <Link className="titleLink" to="/noticias-chilemat">NOTICIAS</Link>
                </div>
                <div id="unete-link" className={splitLocation[1] === "beneficios" ? "activeLink" : ""} onClick={toggle}>
                    <Link className="titleLink" to="/beneficios">Únete como Ferretero</Link>
                    <ul className='submenuDesk'>
                        <li><HashLink smooth to="/beneficios#beneficios-de-pertenecer" scroll={scrollEnd}>beneficios de pertenecer</HashLink></li>
                        <li><HashLink smooth to="/beneficios#testimonios" scroll={scrollCenter}>testimonios</HashLink></li>
                        <li><Link to="/proveedores">Proveedores</Link></li>
                    </ul>
                </div>
                <div id="usuario-link" onClick={toggle}>
                    <div onClick={props.userAccess}>INGRESO USUARIO</div>
                </div>
            </div>
        </div>
    )
}
export default MenuHeader;