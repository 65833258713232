import React from 'react';
import { Row, Col } from 'antd';
import logoChilemat from "../assets/img/logo_chilemat.png"
import MenuHeader from './MenuHeader';
import { Link } from "react-router-dom";

const Header = (props) =>{
    return (
        <header >
            <Row className="rows-header-bottom">
                <Col className="col-header" xl={6} lg={6} md={8} sm={8} xs={1}>
                    <div  className="logo">
                        <Link to="/"> <img alt="Logo Chilemat" src={logoChilemat}/></Link>
                    </div>
                </Col>
                <Col className="menu-container col-header" xl={18} lg={18} md={16} sm={16} xs={23}>
                    <MenuHeader userAccess={props.userAccess} />
                </Col>
            </Row>
        </header>
    )
}
export default Header;