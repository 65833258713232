import { db } from "./firebaseInit";
const recoverCollection = (collection) =>{
    return new Promise((resolve, reject)=>{
        db.collection(collection)
        .get()
        .then(function(querySnapshot) {
        const dataCollection = [];
        querySnapshot.forEach(function(doc) {
            let data=doc.data()
            data["docId"]=doc.id
            dataCollection.push(data)
        });
       resolve(dataCollection)
    })
    .catch(function(error) {
        reject (new Error("No se han podido recuperar las informaciones"))
    });
        
    })
}
export default recoverCollection;
