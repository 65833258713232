import firebase from "firebase/app";
import "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
  apiKey: "AIzaSyD_xNRWGMO0tCMj24rg5mjIIaBG07fGSqs",
  authDomain: "chilemat-cl.firebaseapp.com",
  projectId: "chilemat-cl",
  storageBucket: "chilemat-cl.appspot.com",
  messagingSenderId: "309626931961",
  appId: "1:309626931961:web:357afca08c6e4e46c4f028",
  measurementId: "G-V40W4YW4MH",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export let db = firebase.firestore();