import React from 'react'
import { Row } from 'antd';

const FindStoreTitle = () => {
    return (
        <Row className="findStore-title">
            <h1>
                <span className="featured-text">EN TODO CHILE, </span> 
                MÁS CERCA DE TI
            </h1>
            <h3>
                BUSCA TU FERRETERÍA MÁS CERCANA
            </h3>
        </Row>
    )
}

export default FindStoreTitle