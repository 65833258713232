import { db } from "../../data/firebaseInit";
import React, { useState } from "react";
import sendIcon from "../../assets/contenido/Trabaja con Nosotros/send-form.png";
import logoChilemat from "../../assets/img/logo_Chilemat_cl.svg";
import { Row, Col } from "antd";

const ContactForm = (props) => {
  const [motivo, setMotivo] = useState("");
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [celNumber, setCelNumber] = useState("");
  const [comuna, setComuna] = useState("");
  const [messageContactForm, setMessageContactForm] = useState("");
  const [validationMessage, setValidationMessage] = useState("");

  const sendMessage = (e) => {
    e.preventDefault();
    if (
      nombre === "" ||
      email === "" ||
      celNumber === "" ||
      comuna === "" ||
      motivo === ""
    ) {
      setValidationMessage("Completa los campos solicitados");
    } else {
      setValidationMessage("");
      db.collection("mail").add({
        to: "chilemat.contacto@chilemat.cl",
        message: {
          subject: `${motivo} - ${nombre}`,
          html: `Nombre: ${nombre} <br />E-mail: ${email} <br />Telefono: ${celNumber} <br />Comuna: ${comuna} <br />Message: ${messageContactForm}`,
        },
      });

      // Reset form
      setNombre("");
      setEmail("");
      setCelNumber("");
      setComuna("");
      setMessageContactForm("");
      setMotivo("");
      props.openModal();
    }
  };

  return (
    <section className="ContactForm-section">
      <Row className="ContactForm-content">
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={10}
          xl={10}
          className="ContactForm-Info"
        >
          <img src={logoChilemat} alt="CHILEMAT" />
          <p>
            Complete el formulario para contactarnos, o bien escríbanos a:{" "}
            <span className="text-marked">contacto@chilemat.cl</span>
          </p>
        </Col>
        <Col xs={24} sm={24} md={24} lg={14} xl={14} className="form-container">
          <form>
            <div id="ContactForm-validation">
              <div id="ContactForm-message-validation">{validationMessage}</div>
              <select
                name="selectContactForm"
                id="selectContactForm"
                value={motivo}
                onChange={(e) => setMotivo(e.target.value)}
              >
                <option value="" hidden>
                  Selecciona un Motivo
                </option>
                <option value="Sugerencia">Sugerencia</option>
                <option value="Reclamo">Reclamo</option>
                <option value="Consulta">Consulta</option>
                <option value="Felicitaciones">Felicitaciones</option>
              </select>
              <input
                type="text"
                onChange={(e) => setNombre(e.target.value)}
                value={nombre}
                name="nameContactForm"
                id="nameContactForm"
                placeholder="NOMBRE Y APELLIDO"
              />
              <input
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                name="emailContactForm"
                id="emailContactForm"
                placeholder="E-MAIL"
              />
              <div className="input-PhoneNumber">
                <label>+56</label>
                <input
                  type="tel"
                  onChange={(e) => setCelNumber(e.target.value)}
                  value={celNumber}
                  name="phoneContactForm"
                  id="phoneContactForm"
                  maxLength="9"
                  placeholder="900000000"
                />
              </div>
              <input
                type="text"
                onChange={(e) => setComuna(e.target.value)}
                value={comuna}
                name="comunaContactForm"
                id="comunaContactForm"
                placeholder="COMUNA"
                className="input-comuna"
              />
              <textarea
                name="messageContactForm"
                id="messageContactForm"
                placeholder="MENSAJE"
                onChange={(e) => setMessageContactForm(e.target.value)}
                value={messageContactForm}
              ></textarea>
            </div>
            <button
              type="button"
              onClick={sendMessage}
              className="form-item form-button"
            >
              ENVIAR
              <span>
                <img className="send" src={sendIcon} alt="send" />
              </span>
            </button>
          </form>
        </Col>
      </Row>
    </section>
  );
};

export default ContactForm;
