import React, { useEffect, useState } from 'react'
import CardsInfoMaps from './CardsInfoMaps';
import {styleMyMap} from "./StyleMyMap.js"
import {regiones} from "./autocomplete.js"
import pin from "../../assets/contenido/map/pin.png"
import zoomIn from "../../assets/contenido/map/zoomIn.png"
import zoomOut from "../../assets/contenido/map/zoomOut.png"
import { SearchOutlined } from '@ant-design/icons';
import SearchAutocomplete from './SearchAutocomplete';
import recoverCollection from '../../data/getData';

const FindStoreMap = () => {
  const [allMarkers, setAllMarkers] = useState([]);
  const [locations, setLocations] = useState([]);
  const [infoCards, setInfoCards] = useState([]);
  const [nameSuggestion, setnameSuggestion] = useState([]);
  const [nameInput, setnameInpu] = useState("");
  const [nameStore, setnameStore] = useState([]);
  //custom zoomControls
  const zoomControl = (map)=>{
    document.querySelector('.zoom-control-in').onclick = function() {
      map.setZoom(map.getZoom() + 1);
    };
    document.querySelector('.zoom-control-out').onclick = function() {
      map.setZoom(map.getZoom() - 1);
    };
    map.controls[window.google.maps.ControlPosition.BOTTOM_CENTER].push(
      document.querySelector(".zoom-control")
    );
  }
  
  useEffect(() => {  
    recoverCollection("sucursalesMap")
    .then(point => {
      const stores = point;
      
      const asociado = [];
      const getNameStore = ()=>{
        point.forEach(a=>{
          const nombreAsociado = a.nombre
          return asociado.push(nombreAsociado)
      });}
      
      setnameStore(asociado)
      getNameStore()
      console.log(nameStore)
      point.sort(function (a, b) {
        return a.id - b.id;
      });
      const initMap = () =>{
        // map is declared
        let map = new window.google.maps.Map(document.getElementById('map'), {
          center: {lat: -33.446232, lng: -70.662839},
          zoom: 10,
          mapTypeId: window.google.maps.MapTypeId.ROADMAP,
          rotateControl: false,
          mapTypeControl: false,
          panControl: false,
          crollwheel: true,
          fullscreenControl: false,
          styles: styleMyMap,
          disableDefaultUI: true,
        });
        // Get user's location
        if ('geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition(
            position =>{
              const userPosition = {lat:position.coords.latitude, lng:position.coords.longitude}
              map.setCenter(userPosition);
              
            ;},
            err =>console.log(`Error (${err.code}): ${err.message}`)
          );
        } else {
          alert('Geolocation is not supported by your browser.');
        }
        // This function customizes the zoom controls
        zoomControl(map)
        //declared markers array and infowindow array
        let markers=[];
        let InfoWindows=[];
        // adding markers in the map
        stores.forEach(store=>{
          const  marker = new window.google.maps.Marker({
            position: new window.google.maps.LatLng(store.lat, store.lng),
            map: map,
            id: `sucursalID:${store.id}`,
            title: store.comuna,
            region: store.region,
            name:store.nombre,
            phone: store.fono,
            hour: store.horariosAtencion,
            address: store.direccion,
            web: store.paginaWeb,
            lat: store.lat,
            lng: store.lng,
            picture: store.imagen
          });
          let pinIcon = new window.google.maps.MarkerImage(
            pin,
            null, 
            null, 
            null, 
            new window.google.maps.Size(35, 50)
          );  
          marker.setIcon(pinIcon);
          markers.push(marker)
        })
        //creating the infowindow according to the markers
        markers.forEach(mark => {
          const arrayLinks=[];
          mark.phone.map((phone) => {
            const phonestring= phone.toString();
            let newPhone= null;
            if (phonestring.slice(0,1) === '9'){
              newPhone = ["(", phonestring.slice(0,1), ")", phonestring.slice(1)].join(' ');
            }else{
              newPhone=["(", phonestring.slice(0,2), ")", phonestring.slice(2)].join(' ');
            }
            const links = `<a href="tel:+56${phone}"> ${newPhone}</a>`;
            return arrayLinks.push(links)
          })
          let imagen = ""
          if (mark.picture === ""){
            imagen = ""
          }else{
            imagen = `<img class='infowindow-img' src=${mark.picture}/>`;
          }
          const contentString = 
          `<div class="infowindow-content">
            ${imagen}
            <div class="text-infowindow">
              <h3 class="infoWindow-store-commune">${mark.title}</h3>
              <h6 class="infoWindow-store-name">${mark.name}</h6>
              <p class="infoWindow-store-adress">${mark.address}</p>
              <p class="infoWindow-store-">${mark.hour}</p>
              <p class="infoWindow-store-tel">Fono: ${arrayLinks.join(", ")}</p>
              <a class="infoWindow-store-web" href="http://${mark.web}" target="_blank" rel="noreferrer">${mark.web}</a> 
              <a class="viewInGoogleMaps" target="_blank" rel="noreferrer" href="http://maps.google.com/maps/place/${mark.lat},${mark.lng}/@${mark.lat},${mark.lng},${map.getZoom}z"><div>Cómo llegar</div></a>
            </div>
          </div>`
          const infoWindow = new window.google.maps.InfoWindow({
            content: contentString,
            id: mark.id,
          })
          InfoWindows.push(infoWindow);
          //click in map, close infowindow.
          window.google.maps.event.addListener(map, 'click', function() {
            if (infoWindow) {
                infoWindow.close();
            }
          });
        })
        //change state values
        setInfoCards(InfoWindows);
        setLocations(markers);
        setAllMarkers(markers);
        // Function click in marker
        markers.forEach(marker=>{
          window.google.maps.event.addListener(marker, 'click', ()=>{
            let map= marker.map
            InfoWindows.forEach(val=>{
              if(val.id === marker.id){
                val.open(map, marker);
              }else{
                val.close();
              }
            })
            let sideBarCards = document.querySelectorAll('.infowindow-sidebar');
            sideBarCards.forEach((card)=>{
              if(card.id === marker.id){
                card.scrollIntoView({block: "center"});
                card.classList.add('select-card');
              }else{
                card.classList.remove('select-card');
              }
            });
            map.setZoom(15);
            map.setCenter(marker.getPosition());
          })
        })
        //search
        const inputSearch = (document.getElementById('comuna-search'));
        const options = {
          types: [
            '(cities)'
          ],
          componentRestrictions: { country: 'cl' }
        };
        const autocomplete = new window.google.maps.places.Autocomplete(inputSearch, options);
        autocomplete.bindTo('bounds', map);
        window.google.maps.event.addListener(autocomplete, 'place_changed', function () {
          const place = autocomplete.getPlace();
          const placeName = place.name;
          const filter = markers.filter(
            (marker) =>
            marker.title.toLowerCase().indexOf(placeName.toLowerCase()) > -1
          );
          InfoWindows.forEach(element => {
            element.close();
          });
          if (!place.geometry) {
          // Inform the user that the place was not found and return.
            map.fitBounds((-34.2878805 -70.9577496), (-33.0487158, -69.76899430000003))
            return;
          } else {
            const closestStore=[]
            if(filter.length>0){
              const firstItem = filter[0].position;
              const map= filter[0].map;
              map.setZoom(15);
              map.setCenter(firstItem);
              setLocations(filter)
            }else{
              const latUser =place.geometry.location.lat();
              const lngUser = place.geometry.location.lng();
              const pi = Math.PI;
              const R = 756950; //equatorial radius
              const distances = [];
              let closest = -1;
              for(let i=0;i<markers.length; i++ ) {  
                const lat2 = markers[i].position.lat();
                const lon2 = markers[i].position.lng();
                const chLat = lat2-latUser;
                const chLon = lon2-lngUser;      
                const dLat = chLat*(pi/180);
                const dLon = chLon*(pi/180);
                const rLat1 = latUser*(pi/180);
                const rLat2 = lat2*(pi/180);
                const a = Math.sin(dLat/2) * Math.sin(dLat/2) + 
                            Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(rLat1) * Math.cos(rLat2); 
                const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
                const d = R * c;
                distances[i] = d;
                if ( closest === -1 || d < distances[closest] ) {
                    closest = i;
                }
              }
              closestStore.push(markers[closest]);
              setLocations(closestStore);
              const firstItem = closestStore[0].position;
              const map= closestStore[0].map;
              map.setZoom(15);
              map.setCenter(firstItem);
            }
          }
        });
      } 
      if (!window.google) {
      // Create the script tag, set the appropriate attributes
        const script = document.createElement('script');
        script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAfI7gJREFMFRXlQMR2DAS51VCTN7mCuQA&libraries=places';
        script.async = true;  
        document.head.appendChild(script);
        script.addEventListener('load', e => {
          initMap()
        })
      } else {
        initMap()
      }
      
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function clickItem(store, e) {
    let map = store.map;
    map.setZoom(15);
    map.setCenter(store.position);
    infoCards.forEach(val => {
      if (val.id === store.id) {
        val.open(map, store);
      } else {
        val.close();
      }
    });
    let sideBarCards = document.querySelectorAll('.infowindow-sidebar');
    sideBarCards.forEach((card) => {
      if (card.id === store.id) {
        card.scrollIntoView({ block: "center" });
        card.classList.add('select-card');
      } else {
        card.classList.remove('select-card');
      }
    });
  }

  const filterName=(e)=>{
    const inputValue = e.currentTarget.value;
    const nombres = nameStore;
    const removeDuplicate = () =>{
      let unique = []; 
      nombres.forEach(element => { 
        if (!unique.includes(element)) { 
            unique.push(element); 
        } 
    }); 
    return unique;  
    }
    console.log(nameStore)
    console.log(removeDuplicate())
    setnameInpu(inputValue)
    if (inputValue) {
      const suggestions = removeDuplicate();
      const filteredSuggestions = suggestions.filter(
        (suggestion) =>
          suggestion.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
      );
      setnameSuggestion(filteredSuggestions)
    }else{
      setnameSuggestion([])
    }
    const filter = allMarkers.filter(
      (marker) =>
        marker.name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    );
    if(filter.length<0){
      const firstItem = filter[0].position;
      const map= filter[0].map;
      map.setZoom(15);
      map.setCenter(firstItem);
      setLocations(filter);
    }else if(inputValue===""){
      setLocations(allMarkers)
    }
    else{
      setLocations([]);
    }
  }
  const onClickFilterName=(e)=>{
    const value = e.currentTarget.innerText;
    setnameInpu(value)
    setnameSuggestion([]);
    const filter = allMarkers.filter(
      (marker) =>
        marker.name.toLowerCase().indexOf(value.toLowerCase()) > -1
    );
    if(filter){
      setLocations(filter)
      const firstItem = filter[0].position;
      const map= filter[0].map;
      map.setZoom(15);
      map.setCenter(firstItem);
    }else{
      setLocations([]);
    }
  }

  const selectOnChange = (e)=>{
    const selectValue = e.target.value;
    const selectFilter = allMarkers.filter(marker=>{
      return marker.region === selectValue;
    })
    if(selectFilter.length>=1){
      setLocations(selectFilter);
      const firstItem = selectFilter[0].position;
      const map= selectFilter[0].map;
      map.setZoom(12);
      map.setCenter(firstItem);
    } else if(selectValue===""){
      setLocations(allMarkers)
    }
    else{
      setLocations([]);
    }
  }
  return (
    <>
      <div className="filters-container" onClick={()=>{setnameSuggestion([])}}>
        <div className="filters-row">
          <div className="filter">
            <select onChange={(e)=>selectOnChange(e)} name="region" id="region-filter">
              <option value="">Buscar por región</option>
              {regiones.map(op=>{
              return <option key={op.id} value={op.id}>{op.name}</option>
              })}
            </select>
          </div>
          <div className="filter">
            <input autoComplete="off" type="text" id="comuna-search" placeholder="Buscar por Comuna" />
          </div>
          <div className="filter search-name">
            <input value={nameInput} autoComplete="off" onChange={(e)=>filterName(e)} type="text" id="name-search" placeholder="Buscar por Ferretería" />
            <div className="search-icon"><SearchOutlined /></div>
            <SearchAutocomplete suggestions={nameSuggestion} onClick={(e)=>onClickFilterName(e)}/>
          </div>
        </div>
      </div>
      <div className="container-navigation-maps">
        <div className="inputsAndMap">
          <div id="map" className="findStore-map-container"></div>
          <div className="custom-controls">
            <div className="controls zoom-control">
              <button className="zoom-control-in" title="Zoom In"><img src={zoomIn} alt="+" /></button>
              <button className="zoom-control-out" title="Zoom Out"><img src={zoomOut} alt="-" /></button>
            </div>
          </div>
        </div>
        <div id="sidebar-maps" className="sidebar-map">
          <CardsInfoMaps locations={locations} clickItem={(store, e)=>{clickItem(store, e)}}/>
        </div>
      </div>
    </>
  )
}

export default FindStoreMap