import { db } from "../data/firebaseInit";
import React, { useState } from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
// import axios from "axios";
import logoChilemat from "../assets/img/logo_chilemat.png";
import gps from "../assets/img/icon-gps.png";
import phone from "../assets/img/icon-phone.png";
import commenteme from "../assets/img/COMMENTEME.webp";
import insta from "../assets/contenido/Home/social network/instagram.png";
import face from "../assets/contenido/Home/social network/facebook.png";
import linkedin from "../assets/contenido/Home/social network/linkedin.png";
import newsletter from "../assets/img/newsletter-icon.png";

const Footer = () => {
  const [email, setEmail] = useState("");
  const saveNewsletter = (e) => {
    e.preventDefault();

    console.log(email);

    if (email === "") {
      document.getElementById("newsletter-validation").innerHTML =
        "No has escrito tu correo";
    } else {
      document.getElementById("newsletter-validation").innerHTML = "";

      db.collection("mail").add({
        to: "chilemat.contacto@chilemat.cl",
        message: {
          subject: `Registro de Lead Newsletter`,
          html: `Email: ${email}`,
        },
      });
    }
  };
  return (
    <footer className="footer-2">
      <Row className="newsletter-container row-footer2">
        <Row className="newsletter-row">
          <Col
            xs={24}
            sm={12}
            md={12}
            lg={7}
            xl={7}
            className="newsletter-text newsletter-col"
          >
            <h1>Registra tu correo</h1>
            <p>Conoce todo lo que pasa en la cadena Chilemat</p>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={12}
            lg={10}
            xl={10}
            className="newsletter-form newsletter-col"
          >
            <div className="form-container">
              <input
                type="email"
                name="email"
                placeholder="CORREO ELECTRÓNICO"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <button
                type="button"
                onClick={(e) => {
                  saveNewsletter(e);
                }}
              >
                Suscribirse
              </button>
            </div>
            <div id="newsletter-validation"></div>
          </Col>
          <Col
            xs={0}
            sm={0}
            md={0}
            lg={7}
            xl={7}
            className="newsletter-img newsletter-col"
          >
            <img src={newsletter} alt="e-commerce" />
          </Col>
        </Row>
      </Row>
      <Row className="footer-info-container row-footer2">
        <Row className="info-row">
          <Col
            xs={24}
            sm={12}
            md={8}
            lg={6}
            xl={6}
            className="footer-info-menu footer-info-col"
          >
            <h3 className="title-links-footer-menu">Más informaciones</h3>
            <ul>
              <li>
                <Link to="/contactenos">Contacto</Link>
              </li>
              <li>
                <Link to="/trabaja-con-nosotros">Trabaja con nosotros</Link>
              </li>
              <li>
                <Link to="/unete-como-ferretero">Únete como Ferretero</Link>
              </li>
            </ul>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={8}
            lg={4}
            xl={4}
            className="footer-info-social footer-info-col"
          >
            <h3>Redes Sociales</h3>
            <ul className="social-network">
              <li>
                <a
                  href="https://www.instagram.com/chilemat_com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={insta} alt="instagram" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/ChilematCL"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={face} alt="facebook" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/chilemat/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkedin} alt="Linkedin" />
                </a>
              </li>
            </ul>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={8}
            lg={7}
            xl={7}
            className="footer-info-contact footer-info-col"
          >
            <div className="container-contact addresses-contact">
              <img src={gps} alt="dirección" />
              <div>
                <p>
                AV. Calera de Tango 1553, San Bernardo, Región Metropolitana, Chile
                </p>
              </div>
            </div>
            <div className="container-contact phone-contact">
              <img src={phone} alt="teléfono" className="telefono-footer" />
              <div>
                <p>Fono: (56-2)2 499 8600</p>
              </div>
            </div>
          </Col>
          <Col
            xs={24}
            sm={12}
            md={24}
            lg={7}
            xl={7}
            className="footer-info-logo footer-info-col"
          >
            <img src={logoChilemat} alt="CHILEMAT" />
          </Col>
        </Row>
      </Row>
      <Row className="copyright-container row-footer2">
        <Col span={24}>
          {" "}
          <p className="text-row-footer">
            ©2021 Chilemat.cl. Todos los derechos reservados. Chilemat SPA
          </p>
        </Col>
      </Row>
      <Row className="signature-footer">
        <Row className="signature-section">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="signature-container">
              <p>
                Developer by:
                <a
                  href="https://www.commente.me/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={commenteme} alt="commente.me" />
                </a>
              </p>
            </div>
          </Col>
        </Row>
      </Row>
    </footer>
  );
};

export default Footer;
