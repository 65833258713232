import React, { useEffect, useState } from "react";
import { Row } from "antd";
import Title from "../../components/Title";
import ArrowR from "../../assets/contenido/FerreNoticias/arrowR.png";
import { Link } from "react-router-dom";
import recoverCollection from "../../data/getData";
const TemplateNewStores = ({match, history}) => {
    const {
        params: { id },
      } = match;
      let [news, setNews] = useState([]);
      let [AlsoSee, setAlsoSee] = useState([]);
      let [NotFound, setNotFound]= useState();
      useEffect(() => {
        recoverCollection("noticias")
        .then(response => {
            const data = response.filter((n)=>{
                return n.type.toUpperCase() === "Nuevas Sucursales".toUpperCase()
            })
            data.sort((a, b) => b.date.localeCompare(a.date));
            const deleteItemTemplate = data.filter(news=>{
              return news.url !== id
            })
            const seeAlso = deleteItemTemplate.slice(0, 2,)
            const filternews = data.filter(function (news)
            {
              return news.url === id
            });
            if(filternews.length===0 || filternews.length>1){
                setNotFound(true)
            }else{
                setAlsoSee(seeAlso)
                setNews(filternews);
                setNotFound(false)
            }         
        })
      }, [id]);
return (
    <>  
        {NotFound? 
            <section className="not-found-section">
                <h1>Lo Sentimos</h1>
                <p>No se ha encontrado la Noticia</p>
            </section>
          :
          <section className="news-template-section">
            <Title className="newsTemplate-title-container" value={<Link to="/noticias-chilemat">NOTICIAS</Link>}/>
            <Row className="news-template-container">    
            <div className="news-template-article">       
            {
              news.map((val) =>{
                const date = new Date(val.date);
                const year = date.getFullYear();
                const monthNames = [
                    "Enero",
                    "Febrero",
                    "Marzo",
                    "Abril",
                    "Mayo",
                    "Junio",
                    "Julio",
                    "Agosto",
                    "Septiembre",
                    "Octubre",
                    "Noviembre",
                    "Diciembre",
                ];
                let month = monthNames[date.getMonth()];
                let dt = date.getDate();
                if (dt < 10) {
                    dt = "0" + dt;
                }
                if (month < 10) {
                    month = "0" + month;
                }
                const newDate = `${dt} ${month} ${year}`;
                return(
                  <>
                    <div key={val.id} className="article-header">
                      <div className="header-text">
                        <p>{newDate}</p>
                        <h1>{val.title}</h1>
                      </div>
                      <div className="header-img">
                        <img alt={val.title + " imagen"} src={val.articleImg}/>
                      </div>
                    </div>
                    <div className="article-content">
                      <div className="content-img">
                        <img alt={val.title + " imagen"} src={val.articleImg}/>
                      </div>
                      <div dangerouslySetInnerHTML = {{__html: val.news}}></div>
                    </div>
                  </>
                )                  
                       
              })
            }
             </div>
              <div className="news-template-sideArticle">
                <div className="sideArticle-space"></div>
                <div className="sideArticle-seeAlso">
                  <h1>VEA TAMBIÉN</h1>
                  <div className="seeAlso-cards-container">
                    {AlsoSee.map(val=>{
                      const date = new Date(val.date);
                      const year = date.getFullYear();
                      const monthNames = [
                          "Enero",
                          "Febrero",
                          "Marzo",
                          "Abril",
                          "Mayo",
                          "Junio",
                          "Julio",
                          "Agosto",
                          "Septiembre",
                          "Octubre",
                          "Noviembre",
                          "Diciembre",
                      ];
                      let month = monthNames[date.getMonth()];
                      let dt = date.getDate();
                      if (dt < 10) {
                          dt = "0" + dt;
                      }
                      if (month < 10) {
                          month = "0" + month;
                      }
                      const newDate = `${dt} ${month} ${year}`;
                      return <div key={val.id} className="news-small-card" onClick={()=>{
                        history.push(`/nueva-sucursal/${val.url}`);}}>
                              <div className="img-news-container"
                              style={{ backgroundImage: `url(${val.cardImg})` }}></div>
                              <div className="text-news-container">
                                <p className="news-date">{newDate}</p>
                                <h3  className="news-title">{val.title}</h3>
                                <div className="read-more-button">
                                  <img alt="Flecha dirección Derecha" src={ArrowR} />
                                  VER MÁS
                                </div>
                              </div>
                            </div>
                    })}
                  </div>
                </div>
              </div>             
            </Row>
          </section>
        }
    </>
)
}
export default TemplateNewStores;