import React from 'react'
import FindStoreTitle from '../components/map/FindStoreTitle'
import FindStoreMap from '../components/map/FindStoreMap'

const FindStore = () => {
    return (
        <>
        <section className="findStore-section">
            <FindStoreTitle/>
            <FindStoreMap/>
        </section>
        </>
    )
}

export default FindStore
