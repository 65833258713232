import React , {useEffect, useState} from 'react'
import recoverCollection from '../../data/getData';

const CardsBenefit = () => {
    let [beneficios, setBeneficios] = useState([]);
    useEffect(() => {
        recoverCollection("beneficios")
        .then(bene => {
            bene.sort((a, b) => a.id - b.id)
            setBeneficios(bene)
        })
    }, []);
    return(
        <section id="beneficios-de-pertenecer" className="HomeCardsBenefits-section">
            <div className="HomeCardsBenefits-title">
                FERRETERO
                <h1>BENEFICIOS DE PERTENECER</h1>
            </div>
            <div className="HomeCardsBenefits-container">
                <div className="cardsBenefits-container">
                    {
                        beneficios.map((item) =>{
                            return(
                                <div key={item.docId} title={item.titulo} className='container-col-cards' >
                                    <div className='container-primary'>
                                        <img src={item.foto} alt={"beneficio:" + item.titulo} />
                                    </div>
                                    <div className='container-secondary'>
                                        <p>{item.titulo}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}
export default CardsBenefit