import React from 'react'
import CardsMision from '../components/conocenos/CardsMision'
import Directory from '../components/conocenos/Directory'
import Management from '../components/conocenos/Management'
import TimelineOurHistory from '../components/conocenos/TimelineOurHistory';
import equipe2 from '../assets/contenido/Nuestra Empresa/equipe-la-estancilla.jpg'

const KnowUs = () => {
    return (
        <>
            <TimelineOurHistory/>
            <CardsMision/>
            <Directory/>
            <Management/>
            <div className="squad-chilemat">
                <img src={equipe2} alt="Equipo chilemat - La Estancilla" />
            </div>
        </>
    )
}

export default KnowUs
