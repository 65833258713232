import React, { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./view/Home";
import ProviderContext from "./states/Provider";
import KnowUs from "./view/KnowUs";
import Benefit from "./view/Benefit";
import FindStore from "./view/FindStore";
import BlogNews from "./view/BlogNews";
import TemplateNews from "./view/articleTemplates/TemplateNews";
import Header from "./components/Header";
import Footer from "./components/Footer";
import WorkWithUsTemplate from "./view/WorkWithUsTemplate";
import UserAccess from "./components/ingreso usuario/UserAccess";
import ScrollToTop from "./ScrollToTop";
import TemplateNewStores from "./view/articleTemplates/TemplateNewStores";
import AssociatedFormTemplate from "./view/AssociatedFormTemplate";
import ContactFormTemplate from "./view/ContactFormTemplate";
import Supplier from "./components/Proveedores/Supplier";

import GTM from "react-tag-manager";

function App() {
  const [AccessUser, setAccessUser] = useState(false);
  return (
    <ProviderContext>
      <GTM
        gtm={{
          id: "GTM-W4Z6NKW",
        }}
      />
      <Router>
        <ScrollToTop />
        <Header
          userAccess={() => {
            setAccessUser(true);
          }}
        />
        {AccessUser && (
          <UserAccess
            exit={() => {
              setAccessUser(false);
            }}
          />
        )}
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/conocenos" component={KnowUs} />
          <Route path="/beneficios" component={Benefit} />
          <Route path="/trabaja-con-nosotros" component={WorkWithUsTemplate} />
          <Route path="/encuentra-tu-ferreteria" component={FindStore} />
          <Route path="/noticias-chilemat/:id?" component={BlogNews} />
          <Route path="/noticia/:id" component={TemplateNews} />
          <Route path="/nueva-sucursal/:id" component={TemplateNewStores} />
          <Route
            path="/unete-como-ferretero"
            component={AssociatedFormTemplate}
          />
          <Route path="/contactenos" component={ContactFormTemplate} />
          <Route path="/proveedores" component={Supplier} />
        </Switch>
        <Footer />
      </Router>
    </ProviderContext>
  );
}

export default App;
