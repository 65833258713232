import React, { useEffect, useState } from 'react'
import recoverCollection from '../../data/getData';
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import news from "../../assets/contenido/Home/news.png"
import stores from "../../assets/contenido/Home/stores.png"
import ferretero from "../../assets/contenido/Home/ferretero.png"
import scrolling from "../../assets/contenido/Home/scrolling.png"
// import Swiper core and required modules
import SwiperCore, {
    Autoplay, EffectFade, Pagination
} from 'swiper/core';
import UseWindowSize from '../HookSize';
SwiperCore.use([Autoplay, EffectFade, Pagination]);

const WelcomeHome = () => {
    const size = UseWindowSize();
    const [Banners, setBanners] = useState([]);
    useEffect(() => {
    recoverCollection("banners")
    .then( bann => {
        const bannerSize = [];
        bann.sort((a, b) => a.main - b.main)
        bann.forEach(e => {
            if(size.width>=768){
                const banner = {
                    link: e.link,
                    title: e.title,
                    imagen: e.desktop
                }
                bannerSize.push(banner)
            }if(size.width<=767){
                const banner = {
                    link: e.link,
                    title: e.title,
                    imagen: e.mobile
                }
                bannerSize.push(banner)
            } 
        });
        setBanners(bannerSize)
    })
    }, [size.width]);
    return(
        <section className="welcomehome-section">
            {/**Slider Home */}
            <Swiper className="welcomehome-slider"
            pagination={{
                "clickable": true,}}
            slidesPerView={1}
            initialSlide={1}
            effect={'fade'}
            autoplay={{
                "delay": 6000, 
                "disableOnInteraction": false
            }}
            loop={true}>
                {
                Banners.map((banner, index)=>{
                    console.log(banner)
                    return <SwiperSlide key={index}>
                        {
                            
                            banner.link.length >=  1 ? 
                            <>
                                {
                                   banner.link.indexOf("www.") === -1 ?
                                   <Link className='links_banners' to={banner.link}>
                                    <img src={banner.imagen} alt={banner.title} />
                                    </Link>
                                   :
                                    <a target="_blank" rel="noopener noreferrer" href={banner.link} className='links_banners'>
                                        <img src={banner.imagen} alt={banner.title} />
                                    </a>
                                }
                            </>
                                
                                : 
                                <img src={banner.imagen} alt={banner.title} />
                        }
                        
                    </SwiperSlide>
                })
                }
            </Swiper>
            {/**menu bottom */}
            <div className="welcomehome-row-bottom">
                <div className="welcomehome-img-scroll">
                    <img src={scrolling} alt="seguir bajando" />
                </div>
                <div className="welcomehome-menu-bottom">
                    <Link to="/encuentra-tu-ferreteria">
                        <div className="light-green">
                            <div className="contenido-menu">
                                <div className="text-menu">
                                    <h3>Encuentra tu ferretería</h3>
                                    <p>Sucursales</p>
                                </div>
                                <img src={stores} alt="Encuentra tu ferreteria" />
                            </div>
                        </div>
                    </Link>
                    <Link to="/noticias-chilemat">
                        <div className="medium-green">
                            <div className="contenido-menu">
                                <div className="text-menu medium-green-text-menu">  
                                    {
                                        size.width > 680 ? 
                                        <>
                                            <h3>actualízate ahora</h3>
                                            <p>Noticias</p>
                                        </> 
                                        : 
                                        <p>Noticias</p>
                                    }
                                </div>
                                <img src={news} alt="compra por la web" />
                            </div>
                        </div>
                    </Link>
                    <Link to="/beneficios">
                        <div className="dark-green">
                            <div className="contenido-menu">
                                <div className="text-menu dark-green-text-menu">
                                    {
                                        size.width > 680 ? 
                                        <>
                                            <h3>Únete como Ferretero</h3>
                                            <p>Chilemat</p>
                                        </> 
                                        : 
                                        <p>Únete a Chilemat</p>
                                    }
                                </div>
                                <img src={ferretero} alt="unete como ferretero" />
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </section>
    )
}
export default WelcomeHome;