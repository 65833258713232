import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
// import Swiper core and required modules
import SwiperCore, {
  Navigation, Pagination
} from 'swiper/core';
import recoverCollection from '../../data/getData';
SwiperCore.use([Navigation, Pagination]);

const NewStoresSlider = ({history }) =>{
    let [Testimony, setTestimony] = useState([]);
    useEffect(() => {  
        recoverCollection("noticias")
        .then(response => {
            const data = response.filter((n)=>{
                return n.type.toUpperCase() === "Nuevas Sucursales".toUpperCase()
            })
            data.sort((a, b) => b.date.localeCompare(a.date))
            setTestimony(data)
        })
    }, []);
    return(
        <section className="NewStoresSlider-section">
            <h1>NUEVAS SUCURSALES</h1>
            <Swiper
            className="container-NewStoresSlider"
            initialSlide={0}
            navigation={Navigation}
            slidesPerGroup={1}
            slidesPerView={1}
            pagination={true}
            breakpoints={{
              // when window width is >= 991px
              991: {
                slidesPerView: 3,
                slidesPerGroup: 3,
              }
            }}>
                {
                    Testimony.map((val)=>{
                            const date= new Date (val.date);
                            const year = date.getFullYear();
                            const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
                                "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
                            let month = monthNames[date.getMonth()]
                            let dt = date.getDate();
                    
                            if (dt < 10) {
                            dt = '0' + dt;
                            }
                            if (month < 10) {
                            month = '0' + month;
                            }
                            const newDate = `${dt} ${month} ${year}`
                        return <SwiperSlide key={val.id}>
                            <div onClick={()=>{history.push(`/nueva-sucursal/${val.url}`);}} className="NewStoresSlider-info-container">
                            <div className="NewStoresSlider-img-container" style={{ backgroundImage: `url(${val.cardImg})` }}>
                            </div>
                            <div className="NewStoresSlider-text-container">
                                <p className="NewStoresSlider-date">{newDate}</p>
                                <h3 className="NewStoresSlider-title">{val.title}</h3>
                                <p className="NewStoresSlider-cards-paragraph">{val.resume}</p>
                            </div>
                        </div>
                        </SwiperSlide>
                    })
                }
            </Swiper>
        </section>
    )
}

export default NewStoresSlider;