import React from "react"

const SearchAutocomplete=(props)=>{
    return(
        <>
            {
                props.suggestions && props.suggestions.length >  0  ? 
                <ul className="suggestion-list">
                    {
                        props.suggestions.map(suggestion=>{return(
                            <li  key={suggestion} onClick={(e)=>props.onClick(e)}>{suggestion}</li>
                        )})
                    }
                </ul>
                : 
                <></>
            }
        </>
    )
}
export default SearchAutocomplete;