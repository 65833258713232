import React, {useState} from 'react';
import ModalForm from '../components/formularios/ModalForm';
import AssociatedForm from '../components/formularios/AssociatedForm';

const AssociatedFormTemplate= () => {
    let [modalForm, setModalForm] = useState('');
    return (
        <>
            {modalForm && 
                <ModalForm
                closeModal={()=>{setModalForm(false)}}/>
            }
            <AssociatedForm
            openModal={()=>{setModalForm(true)}}/>
        </>
    );
}

export default AssociatedFormTemplate;