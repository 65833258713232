import React, { useEffect, useState } from 'react'
import icon1 from '../../assets/contenido/Beneficios de Pertenecer/proveedores/icon1.png'
import icon2 from '../../assets/contenido/Beneficios de Pertenecer/proveedores/icon2.png'
import recoverCollection from '../../data/getData';

const Supplier = () => {
  let [supplierData, setSupplierData] = useState([]);
  useEffect(() => {
    recoverCollection("proveedores")
    .then( supp => {
        const supplier = supp.filter((prov)=>{
            return prov.logo.length !==0
        })
        for (let i = supplier.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = supplier[i];
            supplier[i] = supplier[j];
            supplier[j] = temp;
        }
        setSupplierData(supplier)
    })
  }, []);
  return (
    <section id="Proveedores" className='container-view-supplier'>
      <div className='container-supplier'>
        <div className='container-supplier-text'>
          <div className='container-supplier-info'>
            <h1>+300</h1>
            <h2>PROVEEDORES</h2>
              <div className="row-supplier-info" align={'middle'}>
                <div className="img-supplier-info">
                  <img src={icon1}  alt="icono"/>
                </div>
                <div className="text-supplier-info">
                  <p>Disponibles para los asociados</p>
                </div>
              </div>
              <div className="row-supplier-info" align={'middle'} >
                <div className="img-supplier-info">
                  <img src={icon2}  alt="icono"/>
                </div>
                <div className="text-supplier-info">
                  <p>Acceso a las principales marcas y productos del mercado</p>
                </div>
              </div>
          </div>
        </div>

        <div className='container-supplier-logo'>
          <div className='container-supplier-img'>
              {
                supplierData.map((val, index)=>{
                    return (
                    <div  key={index} className='container-logo'>
                      <img src={val.logo}  alt={val.marca} title={val.marca}/>
                    </div>
                    )
                })
            }
            </div>
          </div>
        </div>
    </section>
  )
}

export default Supplier;
