import React from 'react'
import CardsBenefit from '../components/beneficios de pertenecer/CardsBenefit'
import EventsImg from '../components/beneficios de pertenecer/EventsImg';
import HowToBePart from '../components/beneficios de pertenecer/HowToBePart';
import Testimony from '../components/beneficios de pertenecer/Testimony';
import TrainingsImg from '../components/beneficios de pertenecer/TrainingsImg';

const Benefit = () => {
    return (
        <>
            <CardsBenefit/>
            <Testimony/>
            <EventsImg/>
            <TrainingsImg/>
            <HowToBePart id="Como-ser-Parte"/>
        </>
    )
}

export default Benefit;