import React, {useEffect, useState} from 'react'
import recoverCollection from '../../data/getData'
import UseWindowSize from '../HookSize';
const Management = () => {
    const size = UseWindowSize();
    let [gerencia, setGerencia] = useState([]);
    useEffect(() => {
        recoverCollection("gerencia")
        .then(gerentes => {
            if(size.width>=992){  
                gerentes.sort((a, b) => a.orden - b.orden);
                setGerencia(gerentes)
            }if(size.width<=991){
                gerentes.sort((a, b) => a.mobile - b.mobile);
                setGerencia(gerentes)
            }
        })

    }, [size.width]);
    return (
        <section id='nuestro-equipo' className='container-management'>
            <div className="management-title">
                Nuestro Equipo
                <h1>Nuestro Equipo</h1>
            </div>
            <div className="row-management">
                <div className="management-container">
                    {
                        gerencia.map((item) =>{
                            return(
                                <div key={item.docId} className='container-col-cards' >
                                    <div className='container-primary'>
                                        <img src={item.foto} alt={item.nombre} />
                                    </div>
                                    <div className='container-secondary'>
                                        <div className="info-gerente">
                                            <p className="nombre">{item.nombre }</p>
                                            <p className="nombre">{item.apellidos}</p>
                                            <p className="cargo">{item.cargo}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default Management
