import { db } from "../../data/firebaseInit";
import React, { useContext } from 'react';
import { Row, Col, Input } from 'antd';
import sendIcon from '../../assets/contenido/Trabaja con Nosotros/send-form.png';
import people from '../../assets/contenido/Trabaja con Nosotros/Chilemat-trabaja-con-nosotros.png';
import MyContext from '../../states/MyContext';

const WorkWithUs = (props) => {
  const { state, dispatch } = useContext(MyContext);
  const { name, email, area, phone, cv, motivo } = state;

  const onChange = (e) => {
    dispatch({
      type: "saveUserInformation",
      field: e.target.name,
      value: e.target.value
    });
  };

  const upload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      dispatch({ type: 'uploadFile', payload: reader.result.split(',')[1] });
    };
    reader.readAsDataURL(file);
  };
  const submitMail = (e) => {
    e.preventDefault();
console.log(cv)
    if (name === '' || email === '' || phone === '' || area === '') {
      document.getElementById("validation").style.border = 'solid red';
      document.getElementById("message-validation").innerHTML = "Completa los campos solicitados";
      document.getElementById("message-validation").style.color = 'red';
    } else {
      document.getElementById("validation").style.border = 'transparent';
      document.getElementById("message-validation").innerHTML = "";
        db.collection("mail").add({
          to: "rrhh@chilemat.cl",
          message: {
            subject: `Postulación Laboral: ${area} - ${name}`,
            html: `Nombre: ${name} <br />E-mail: ${email} <br />Telefono: ${phone} <br />Área a la que deseo postular: ${area} <br />Lo que me motiva a trabajar con ustedes: ${motivo}`,
            attachments: cv ? [
              {
                filename: `${name}-cv.pdf`,
                content: cv,
                encoding: 'base64'
              }
            ] : []
          },
        });
      dispatch({ type: 'reset' });
      props.openModal();
    }
  };

  return (
    <section className="container-work-with-us">
      <div className="work-whit-us-top"></div>
      <div className="work-whit-us-bottom">
        <Row className="form-container">
          <Col className="container-text-form" xs={24} sm={24} md={24} lg={11} xl={11}>
            <h1>TRABAJA CON <span className="text-marked">NOSOTROS</span></h1>
            <p>
              Complete el formulario para contactarnos,
              o bien escríbanos a: <span className="text-marked">
                rrhh@chilemat.cl
              </span>
            </p>
            <img className="people-chilemat-img" src={people} alt="personas-trabaja con nosotros" />
          </Col>
          <Col className="container-inputs-form" xs={24} sm={24} md={24} lg={13} xl={13}>
            <form className="work-with-us-form" encType="multipart/form-data">
              <div id='validation'>
                <div id="message-validation"></div>
                <input
                  value={state.name}
                  name="name"
                  id="name"
                  onChange={onChange}
                  placeholder="NOMBRE COMPLETO"
                  className="form-item"
                  type='text'
                />
                <input
                  value={state.email}
                  name="email"
                  id="email"
                  onChange={onChange}
                  placeholder="E-MAIL"
                  className="form-item"
                  type='email'
                />
                <Input
                  value={state.phone}
                  name="phone"
                  id="phone"
                  maxLength="8"
                  onChange={onChange}
                  placeholder="900000000"
                  className="form-item phone-item-form"
                  prefix="+56"
                />
                <input
                  value={state.area}
                  name="area"
                  id="area"
                  onChange={onChange}
                  placeholder="ÁREA QUE POSTULA"
                  className="form-item"
                  type="text"
                />
                <input
                  value={state.motivo}
                  name="motivo"
                  id="motivo"
                  onChange={onChange}
                  placeholder="¿QUE TE MOTIVA A POSTULAR?"
                  className="form-item"
                  type="text"
                />
              </div>
              <div className="form-item container-upload">
                <label className="label-upload-file">ADJUNTA TU CV</label>
                <div id='validation-file' className="box-container-upload">
                  <div id='message-file'></div>
                  <input
                    onChange={upload}
                    className="form-upload-file"
                    type="file"
                    name="cv"
                    id="files"
                  />
                </div>
              </div>
              <button onClick={submitMail} className="form-item form-button">
                ENVIAR INFORMACIÓN
                <span>
                  <img className="send" src={sendIcon} alt="send" />
                </span>
              </button>
            </form>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default WorkWithUs;
