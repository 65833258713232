import React, {useEffect, useState} from 'react'
import recoverCollection from '../../data/getData'

const Directory = () => {
    let [directorioColum1, setdirectorioColum1] = useState([]);
    let [directorioColum2, setdirectorioColum2] = useState([]);
    useEffect(() => {
        recoverCollection("directores")
        .then(directores => {
            directores.sort((a, b) => a.orden - b.orden);
            const filterCol1 = directores.filter(element=>{return element.cargo.toUpperCase() === "PRESIDENTE" || element.cargo.toUpperCase()==="PRESIDENTA" || element.cargo.toUpperCase()==="VICEPRESIDENTE" || element.cargo.toUpperCase()==="VICEPRESIDENTA"});
            const filterCol2 = directores.filter(element=>{return element.cargo.toUpperCase() !== "PRESIDENTE" && element.cargo.toUpperCase() !=="VICEPRESIDENTA" && element.cargo.toUpperCase()!=="PRESIDENTA" && element.cargo.toUpperCase()!=="VICEPRESIDENTE" });    
            setdirectorioColum1(filterCol1);
            setdirectorioColum2(filterCol2);
        })
    }, []);
    return (
        <section id="Directorio" className='container-directory'>
            <div className="directory-title">
                Directorio
                <h1>Directorio</h1>
            </div>
            <div className="directory-row">
                <div className="directory-cards-container directory-col1">
                    {
                        directorioColum1.map((item) =>{
                            return(
                                <div key={item.docId} className='container-col-cards' >
                                    <div className='container-primary'>
                                        <img src={item.foto} alt={item.nombre} />
                                    </div>
                                    <div className='container-secondary'>
                                        <p className="nombre">{item.nombre }</p>
                                        <p className="nombre">{item.apellidos}</p>
                                        <p className="cargo">{item.cargo}</p>
                                        <p className="ferreteria">{item.ferreteria}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className="directory-cards-container directory-col2">
                    {
                        directorioColum2.map((item) =>{
                            return(
                                <div key={item.docId} className='container-col-cards' >
                                    <div className='container-primary'>
                                        <img src={item.foto} alt={item.nombre} />
                                    </div>
                                    <div className='container-secondary'>
                                        <div className="info-director">
                                            <p className="nombre">{item.nombre }</p>
                                            <p className="nombre">{item.apellidos}</p>
                                            <p className="cargo">{item.cargo}</p>
                                            <p className="ferreteria">{item.ferreteria}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </section>
    )
}

export default Directory
