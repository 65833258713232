import React from 'react';
import { Row, Col} from 'antd';
import sendIcon from '../../assets/contenido/Trabaja con Nosotros/send-form.png'
const UserAccess =(props)=>{
    return(  
        <Row className="UserAccess-modal">
            <Col className="UserAccess-container">
                <div className="UserAccess-exit" onClick={props.exit}>X</div>
                <h1>¿Cómo deseas ingresar?</h1>
                <a target='_blank' href="https://portal-prd.chilemat.cl/index.html" rel="noreferrer">
                    <div className="UserAccess-options">Asociados <img src={sendIcon} alt="icono de flecha" /></div>
                </a>
                <a target='_blank' href="https://portal-prd.chilemat.cl/index.html" rel="noreferrer">
                    <div className="UserAccess-options last-option">Proveedores <img src={sendIcon} alt="icono de flecha" /></div>
                </a>
            </Col>
        </Row>
      )
}

export default UserAccess;