import React from 'react'
import UseWindowSize from '../HookSize'
import advantagedesk from '../../assets/contenido/Home/advantagedesk.png'
import advantagemob from '../../assets/contenido/Home/advantagemob.png'
/* import car from '../../assets/contenido/Beneficios de Pertenecer/car.png'
import sale from '../../assets/contenido/Beneficios de Pertenecer/sale.png'
import ticket from '../../assets/contenido/Beneficios de Pertenecer/ticket.png'
import tic from '../../assets/contenido/Beneficios de Pertenecer/tic.png'
import hand from '../../assets/contenido/Beneficios de Pertenecer/mano.png' */

const Advantage = ({history}) => {
    const size = UseWindowSize();
    /* const dataAdvantage =[
        {
            advantageImg: car,
            advantageText: "La mejor variedad de productos"
        },
        {
            advantageImg: sale,
            advantageText: "Las mejores marcas"
        },
        {
            advantageImg: ticket,
            advantageText: "Mejores precios"
        },
        {
            advantageImg: tic,
            advantageText: "Mayor Competitividad"
        },
        {
            advantageImg: hand,
            advantageText: "Acceso a Ferias Exclusivas con proveedores"
        },
    ] */
    return (
        <section className='container-advantage'>
            <div className="advantage-title">
                Únete
                <h1>¿Porque elegir Chilemat?</h1>
            </div>
            {
                size.width>=560
                ?
                <img className="advantageimg" src={advantagedesk} alt="ventajas" />
                :
                <img className="advantageimg" src={advantagemob} alt="ventajas" />
            }
            {/* <h2>VENTAJAS COMERCIALES</h2>
            <div className='container-row-advantage'>
                <div className="container-advantages">
                    {
                        dataAdvantage.map((val, index) =>{
                            return <div key={index} className='container-col-advantage'>
                            <div className='container-img-advantage'>
                                <img src={val.advantageImg} alt=''/>
                            </div>
                            <div className="container-text-advantage">
                                <p>{val.advantageText}</p>
                            </div>   
                        </div>
                        })
                    }
                </div>
                <div className="clear-div"></div>
            </div>
            <button onClick={()=>{history.push("/contacto")}}>QUIERO SER PARTE</button> */}
        </section>
    )
}

export default Advantage