import React, {useEffect, useState} from 'react'
import recoverCollection from '../../data/getData';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {Navigation, Pagination, Lazy} from 'swiper/core';
SwiperCore.use([Navigation, Pagination, Lazy]);

const Testimony = () => {
    let [Testimony, setTestimony] = useState([]);
    useEffect(() => { 
        recoverCollection("testimonio")
        .then(tes => {  
            const filterActive = tes.filter(ts=>{
                return ts.muestra !== false
            })
            for (let i = filterActive.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                const temp = filterActive[i];
                filterActive[i] = filterActive[j];
                filterActive[j] = temp;
            }
            setTestimony(filterActive)   
          })
    }, []);
    return(
        <section id="testimonios" className="SliderHomeTestimony-section">
            <div className="SliderHomeTestimony-title">
                TESTIMONIOS
                <h1>NUESTROS ASOCIADOS</h1>
            </div>
            <Swiper 
            navigation={true} 
            slidesPerView={1}  
            slidesPerGroup={1}
            breakpoints={{
                // when window width is >= 768px
                992: {
                    slidesPerView: 2,
                    slidesPerGroup:2
                }}} 
            className="testimony-row-cards">
                {
                    Testimony.map((val)=>{
                        return (
                            <SwiperSlide
                            className="testimony-col-cards" 
                            key={val.docId}>
                                <div className="testimony-info-container">
                                    <div className="testimony-img-container" style={{ backgroundImage: `url(${val.img})` }}></div>
                                    <div className="testimony-text-container">
                                        <div className="testimony-cards-paragraph" dangerouslySetInnerHTML = {{__html: val.text}}></div>
                                        <div className="names-container">
                                            <p>{val.ferreteria}</p>
                                            <h3>{val.name}</h3>
                                            <p>{val.city}</p>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
        </section>
    )
}
export default Testimony;