import React from "react";
import StoresImg from "../components/Home components/StoreImg";
import WelcomeHome from "../components/Home components/WelcomeHome";
import UseWindowSize from "../components/HookSize";

import infodesk from '../assets/contenido/Home/informaciones/home-desktop.png'
import infomob from '../assets/contenido/Home/informaciones/home-mobile.png'
import Advantage from "../components/Home components/Advantage";
import SupplierSlider from "../components/Home components/supplierSlider";

const Home = () => {

  const size = UseWindowSize();

  return (
    <div>
      <WelcomeHome/>
      <div className="section-info-home">
        {
          size.width>=560
          ?
          <img className="info-home-img" src={infodesk} alt="La cadena ferretera más extensa del país" />
          :
          <img className="info-home-img" src={infomob} alt="La cadena ferretera más extensa del país" />
        }
      </div>
      <Advantage/>
      <StoresImg/>
      <SupplierSlider/>
    </div>
  );
};
export default Home;
