import React,{useState, useEffect} from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {Navigation, Pagination, Lazy} from 'swiper/core';
import recoverCollection from '../../data/getData';
SwiperCore.use([Navigation, Pagination, Lazy]);

const TrainingsImg = () =>{
    let [trainings, setTrainings] = useState([]);
    useEffect(() => {
        recoverCollection("capacitaciones")
        .then(cap => {
            cap.sort((a, b) => a.titulo.localeCompare(b.titulo))
            setTrainings(cap)
        })
    }, []);
    return(    
        <section className="section-TrainingsImg">
            <div className="TrainingsImg-title">
                únete
                <h1>Capacitaciones</h1>
            </div>
            <Swiper slidesPerView={1}
            breakpoints={{
            // when window width is >= 768px
            768: {
                slidesPerView: 2,
            }}}
            loop={true} 
            navigation={true} 
            initialSlide={2}
            className="carousel-imgs">
                {
                    trainings.map((val, index)=>{
                        return <SwiperSlide key={val.docId}>
                            <img alt={val.titulo} title={val.titulo} src={val.foto} />
                            </SwiperSlide>
                    })
                }
            </Swiper>
        </section>
    )
}
export default TrainingsImg;