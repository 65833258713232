import React, {useState} from 'react';
import WorkWithUs from '../components/formularios/WorkWithUs';
import ModalForm from '../components/formularios/ModalForm';

const WorkWithUsTemplate= () => {
    let [modalForm, setModalForm] = useState('');
    return (
        <>
            {modalForm && 
                <ModalForm
                closeModal={()=>{setModalForm(false)}}/>
            }
            <WorkWithUs
            openModal={()=>{setModalForm(true)}}/>
        </>
    );
}

export default WorkWithUsTemplate;