import React from 'react'

const CardsInfoMaps = (props) => {
    return (
        <>
        {
            props.locations.map((store)=>{
                return(
                    <div id={store.id} key={store.id} onClick={(e)=>{props.clickItem(store, e)}} className="infowindow-sidebar">
                        <div className="text-infowindow">
                            <h3 className="infoWindow-store-commune">{store.title}</h3>
                            <h6 className="infoWindow-store-name">{store.name}</h6>
                            <p className="infoWindow-store-adress">{store.address}</p>
                            <p className="infoWindow-store-">{store.hour}</p>
                            {store.phone && store.phone.length >  0 ?
                                <p className="infoWindow-store-tel"> Fono: {store.phone.map((phone, index)=>{
                                    const phonestring= phone.toString();
                                    let newPhone = null;
                                    if (phonestring.slice(0,1) === '9'){
                                        newPhone = ["(", phonestring.slice(0,1), ")", phonestring.slice(1)].join(' ');
                                    }else{
                                        newPhone=["(", phonestring.slice(0,2), ")", phonestring.slice(2)].join(' ');
                                    }
                                    return(
                                        <a key={index} href={`tel:+56${phone}`}>{newPhone} </a>
                                    )
                                })}</p>
                                :
                                <></>
                            }
                        </div>
                    </div>
                )
            })
        }
        </>
    )
}

export default CardsInfoMaps