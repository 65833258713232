/* eslint-disable jsx-a11y/alt-text */
import React,{useEffect, useState} from 'react';

import recoverCollection from '../../data/getData'
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {Navigation, Pagination, Lazy} from 'swiper/core';
SwiperCore.use([Navigation, Pagination, Lazy]);

const StoresImg = () =>{
    let [stores, setStores] = useState([]);
    useEffect(() => {
        recoverCollection("sucursalesHome")
        .then(stores => {
            for (let i = stores.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                const temp = stores[i];
                stores[i] = stores[j];
                stores[j] = temp;
            }
            setStores(stores)
        })
    }, []);
    return(    
        <section className="section-StoresImg">
            <div className="StoresImg-title">
                ferreterías
                <h1>nuestros asociados</h1>
            </div>
            <Swiper slidesPerView={1} slidesPerColumn={2} 
            slidesPerGroup={1}
            slidesPerColumnFill="row"
            initialSlide={0}
            breakpoints={{
                1200: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                },
                680: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                }
            }}
            navigation={true} 
            className="carousel-imgs">
                {
                    stores.map((val, index)=>{
                        return <SwiperSlide key={val.docId}>
                            <img alt={val.nombre} title={val.nombre} src={val.foto} />
                            </SwiperSlide>
                    })
                }
            </Swiper>
        </section>
    )
}
export default StoresImg;