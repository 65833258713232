const InitialState = {
    //email work with us
    name:'',
    email:'',
    phone:'',
    area:'',
    motivo:'',
    cv: '',
    modalForm: false,
    // email work with us
    
    //contact form//
    nameContactForm:"",
    emailContactForm:"",
    phoneContactForm:"",
    comunaContactForm:"",
    directionAssociatedForm:"",
    ferreContactForm:"",
    regionAssociatedForm:"",
    lastnameAssociatedForm:""
    //contact form//
}
export default InitialState