import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
// import Swiper core and required modules
import SwiperCore, {
  Navigation, Pagination
} from 'swiper/core';
import recoverCollection from '../../data/getData';
SwiperCore.use([Navigation, Pagination]);

const SupplierSlider = () => {
  let [supplierData, setSupplierData] = useState([]);
  useEffect(() => {
    recoverCollection("proveedores")
    .then( supp => {
        const supplier = supp.filter((prov)=>{
            return prov.logo.length !==0
        })
        for (let i = supplier.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = supplier[i];
            supplier[i] = supplier[j];
            supplier[j] = temp;
        }
        setSupplierData(supplier)
    })
  }, []);
    return (
        <section id="Proveedores" className='container-carrusel-supplier'>
            <div className="SupplierSlider-title">
                Proveedores
                <h1>Las mejores marcas</h1>
            </div>
            <Swiper
            className="container-supplier"
            navigation={Navigation}
            freeMode={true}
            slidesPerView={1}
            initialSlide={1}
            slidesPerGroup={1}
            slidesPerColumn={2} 
            slidesPerColumnFill="row"
            breakpoints={{
                // when window width is >= 991px
                1500: {
                    slidesPerView: 7,
                    slidesPerGroup: 7,
                },
                1200: {
                    slidesPerView: 5,
                    slidesPerGroup: 5,
                },
                450: {
                    slidesPerView: 3,
                    slidesPerGroup: 3,
                }
            }}>
                {
                    supplierData.map((val, index)=>{
                        
                        return <SwiperSlide  key={index}>
                            <div className="container-img">
                                <img src={val.logo}  alt={val.marca} title={val.marca}/>
                            </div>
                        </SwiperSlide>
                    })
                }
            </Swiper>
        </section>
    )
}

export default SupplierSlider;