import React, {Component} from 'react';
import { Row } from 'antd';
class Title extends Component{
    render(){
        return(  
        <Row className={this.props.className}>
        <h1>{this.props.value}</h1>
        </Row>
      )
    }
}


export default Title;