import React, {useEffect, useState} from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
    EffectFade,Navigation,Pagination, Autoplay
  } from 'swiper/core';
import recoverCollection from '../../data/getData';
SwiperCore.use([EffectFade,Navigation,Pagination, Autoplay]);

const TimelineOurHistory=()=>{
  let [ourHistory, setOurHistory] = useState([]);
    useEffect(() => {
        recoverCollection("timeline")
        .then(point => {       
          point.sort(function (a, b) {
            const ma = a.year * 1
            const me = b.year * 1
            return ma - me;
          });
          setOurHistory(point)   
        })
    }, []);
  //variable que contiene las reglas de paginación de la time line.
  const pagination = {
    "clickable": true,
    "renderBullet": function ( index, className) {
        var year = document.querySelectorAll('.swiper-slide')[index].getAttribute('data-year');
        return '<span class="' + className + '">' + year + '</span>';
      },
  }
  //funcion del boton "ver más"
  const seeMore =()=>{
    const textHistory = document.getElementsByClassName('read-more-timeline');
    for (let i=0;i<textHistory.length;i+=1){
      textHistory[i].style.display = 'block';
    }
    const seeMoreButton = document.getElementsByClassName('see-more-button');
    for (let e =0;e<seeMoreButton.length;e+=1){
      seeMoreButton[e].style.display = 'none';
    }
    const seeLessButton = document.getElementsByClassName('see-less-button');
    for (let j =0;j<seeLessButton.length;j+=1){
      seeLessButton[j].style.display = 'block';
    }
  }
  //funcion del boton "ver menos"
  const seeLess =()=>{
    const textHistory = document.getElementsByClassName('read-more-timeline');
    for (var i=0;i<textHistory.length;i+=1){
      textHistory[i].style.display = 'none';
    }
    const seeMoreButton = document.getElementsByClassName('see-more-button');
    for (let e =0;e<seeMoreButton.length;e+=1){
      seeMoreButton[e].style.display = 'block';
    }
    const seeLessButton = document.getElementsByClassName('see-less-button');
    for (let j =0;j<seeLessButton.length;j+=1){
      seeLessButton[j].style.display = 'none';
    }
  }
  return(
    <section id="Nuestra-Empresa" className="timeline-our-history-section">
      <Swiper className="mySwiper"
      navigation={Navigation}
      pagination={pagination}
      /* autoplay={{
        "delay": 5000,
        "pauseOnMouseEnter":true,
        "disableOnInteraction": false
      }} */ >
        <SwiperSlide className="chilemat-history-slide" data-year="Chilemat">
          <div className="space-timeline-left"></div>
          <div className="text-chilemat-history">
            <h1 className="timeline-year">Nuestra Historia</h1>
            <p className="timeline-text">
              ¡Chilemat nació en 1995 con la fuerza y el ingenio de los ferreteros!
            </p>
            <p className="timeline-text">
              En los noventa, vimos cómo las cadenas internacionales entraban con fuerza al mercado, ¿qué podíamos hacer frente a ellos?
            </p>
            <p className="timeline-text">
              Pero como un sólido enlace de acero, los Ferreteros se unieron no solo para resistir, ¡sino para destacar!
            </p>
            <p className="timeline-text">
              Así es como Chilemat se fortaleció, con nuestro valor clave: “La unión”. Y con esfuerzo, profesionalismo y determinación, hemos dejado huella en más de 188 ferreterías en 29 años. Desde grandes ciudades hasta rincones rurales, ¡Estamos donde otros no llegan!
            </p>
            <p className="timeline-text read-more-timeline">
              Queremos que tu ferretería forme parte de esta historia de éxito. ¡Únete a nosotros!
            </p>
            <div className='text-buttons-timeline-container'>
              <div onClick={seeMore} className='see-more-button'>Ver más</div>
              <div onClick={seeLess} className='see-less-button'>Ver menos</div>
            </div>
          </div>
          <div className="space-timeline"></div>
        </SwiperSlide>
        {
          ourHistory.map((val, index)=>{                                 
            return <SwiperSlide key={index} data-year={val.year}>
                <div className="img-container-timeline">
                    <img src={val.imgYear} alt="timeline-img"/>
                </div>
                <div className="text-container-timeline">
                    <h1 className="timeline-year">{val.year}</h1>
                    <p className="timeline-text without-margin">{val.text}</p>
                </div>
                <div className="timeline-img-mobile">
                    <img src={val.imgYear} alt="timeline-img"/>
                </div>
          </SwiperSlide>
          })
        }
      </Swiper>      
    </section>
  )
}
export default TimelineOurHistory;
