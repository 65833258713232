/* eslint-disable jsx-a11y/alt-text */
import React,{useState, useEffect} from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {Navigation, Pagination, Lazy} from 'swiper/core';
import recoverCollection from '../../data/getData';
SwiperCore.use([Navigation, Pagination, Lazy]);

const EventsImg = () =>{
    let [events, setEvents] = useState([]);
    useEffect(() => {
        recoverCollection("eventos")
        .then(eve => {
            eve.sort((a, b) => a.titulo.localeCompare(b.titulo))
            setEvents(eve)
        })
    }, []);
    return(    
        <section className="section-EventsImg">
            <div className="EventsImg-title">
                eventos
                <h1>con proveedores</h1>
            </div>
            <Swiper slidesPerView={1}
            breakpoints={{
            // when window width is >= 768px
            768: {
                slidesPerView: 2,
            }}}
            initialSlide={2}
            loop={true} 
            navigation={true} 
            className="carousel-imgs">
                {
                    events.map((val, index)=>{
                        return <SwiperSlide key={val.docId}>
                            <img alt={val.titulo} title={val.titulo} src={val.foto} />
                        </SwiperSlide>
                    })
                }
            </Swiper>
        </section>
    )
}
export default EventsImg;