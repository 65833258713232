import React from 'react'
import mision from "../../assets/contenido/Nuestra Empresa/mision/mision.png"
import proposito from "../../assets/contenido/Nuestra Empresa/mision/proposito.png"
import vision from "../../assets/contenido/Nuestra Empresa/mision/vision.png"
import valores from "../../assets/contenido/Nuestra Empresa/mision/valores.png"
import estrategia from "../../assets/contenido/Nuestra Empresa/mision/estrategia.png"
import { Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {
    Navigation, Pagination
  } from 'swiper/core';
  SwiperCore.use([Navigation, Pagination]);

const CardsMision = ({history}) => {
    const cardsInfo= [
        {id:1, name: "PROPÓSITO", img:proposito, text:"Crear valor, desarrollo sostenible y sentido de pertenencia a nuestros ferreteros"},
        {id:2, name: "MISIÓN", img:mision, text:"Desarrollar servicios de excelencia, para dar valor a nuestros ferreteros y sus clientes"},
        {id:3, name: "VISIÓN", img:vision, text:"Consolidarnos como la marca de ferreterías más reconocida y valorada de Chile y ser la primera opción de compra del mercado"},
        {id:4, name: "VALORES", img:valores, text:"Desarrollar integralmente a nuestros colaboradores y ferreteros, a través de una cultura organizacional basada en la ética, innovación, alegría, espíritu de superación y amor por lo que hacemos"},
        {id:5, name: "ESTRATEGIA", img:estrategia, text:"Trabajando con pasión para lograr servicios de excelencia"},
    ]
    return (
        <section id="mision-vision-y-valores" className ='CardsMision-Section'>
           <Swiper
            className="container-card-mision"
            navigation={Navigation}
            slidesPerView={1}
            breakpoints={{
                // when window width is >= 768px
                660: {
                    slidesPerView: 2,
                },
                970: {
                    slidesPerView: 3,
                },
                1240: {
                    slidesPerView: 4,
                },
                1430: {
                    slidesPerView: 5,
                }
            }}
            >
                {
                    cardsInfo.map((val)=>{
                        return <SwiperSlide key={val.id}>
                            <div className="container-slide" key={val.id}>
                                <div className="cards-info">
                                    <div className="frontFace-card">
                                        <img src={val.img} alt="" />
                                    </div>
                                    <div className="backFace-card">
                                        <h3>{val.name}</h3>
                                        {val.text}
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    })
                }
            </Swiper>
        </section>
    )
}

export default CardsMision;
