import React from 'react';
import { Row, Col} from 'antd';
import checkIcon from '../../assets/contenido/Trabaja con Nosotros/check-form.png'
import logoChilemat from "../../assets/img/logo_Chilemat_cl.svg"
 
const ModalForm = (props) =>{
    const confirmation = ()=>{
        props.closeModal()
    }
return(    
        <Row className="modal-form">
            <Col className="modal-content">
                    <div className="check-icon">
                        <img src={checkIcon} alt="check message" />                
                    </div>
                <h1>Tus datos han sido enviados Correctamente</h1>
                <p>Recibimos tus datos y estaremos en contacto</p>
                <button onClick={confirmation} className='buttonConfirmation'>Aceptar</button>
                <div className="logo-container" >
                    <img className="logo" src={logoChilemat} alt="logo-chilemat"/>
                </div>
            </Col>
        </Row>
)
}
export default ModalForm;