import InitialState from './InitialState'
function reducer(state, action) {
    switch (action.type) {
    case 'reset':
        return InitialState
    case 'saveUserInformation':
        return{
            ...state,
            [action.field]: action.value
        }
    case 'uploadFile':
       
        return{
             ...state,
             cv: action.payload
        }
    case 'confirmationForm':
        return{
             ...state,
             modalForm: action.value
        }
    case 'openPictureCarousel':
        return{
            ...state,
            imgCarouselBenrfit: action.payload,
            modalCardsBenefit: true
        }
        
    default:
        throw new Error()
    }
}
export default reducer