import React, {useState} from 'react';
import ModalForm from '../components/formularios/ModalForm';
import ContactForm from '../components/formularios/ContactForm';

const ContactFormTemplate= () => {
    let [modalForm, setModalForm] = useState('');
    return (
        <>
            {modalForm && 
                <ModalForm
                closeModal={()=>{setModalForm(false)}}/>
            }
            <ContactForm
            openModal={()=>{setModalForm(true)}}/>
        </>
    );
}

export default ContactFormTemplate;