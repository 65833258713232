export const regiones =[
    {id:"XV", name:"Región de Arica y Parinacota"},
    {id:"I", name:"Región de Tarapacá",},
    {id:"II", name:"Región de Antofagasta"},
    {id:"III", name:"Región de Atacama"},
    {id:"IV", name:"Región de Coquimbo"},
    {id:"V", name:"Región de Valparaíso"},
    {id:"METROPOLITANA", name:"Región Metropolitana de Santiago"},
    {id:"VI", name:"Región del Libertador Gral. Bernardo O’Higgins"},
    {id:"VII", name:"Región del Maule"},
    {id:"XVI", name:"Región de Ñuble"},
    {id:"VIII", name:"Región del Biobío"},
    {id:"IX", name:"Región de La Araucanía"},
    {id:"XIV", name:"Región de Los Ríos"},
    {id:"X", name:"Región de Los Lagos"},
    {id:"XI", name:"Región Aysén del Gral. Carlos Ibáñez del Campo"},
    {id:"XII", name:"Región de Magallanes y Antártica Chilena"},
]